// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import $ from 'jquery';
global.$ = jQuery;
import "bootstrap"
const flatpickr = require("flatpickr");
require("select2")
import "chartkick/chart.js"

$(document).ready(function () {

    $(".flash-msg").delay(4000).fadeOut(1000, function () {
        $(".flash-msg alert").alert('close');
    });

    $('a[data-toggle="tooltip"]').tooltip();

    $(".flatpickr").flatpickr({
        enableTime: true,
        dateFormat: "Y-m-d H:i",
    });

});


global.toastr = require("toastr")

toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-right mt-5",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "400",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}



import "controllers"

require("trix")
require("@rails/actiontext")
import DOMPurify from 'dompurify';